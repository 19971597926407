import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import Table from "react-bootstrap/Table";

const AllDetailOfPerson = ({ showAllDetails, setShowAllDetails, data }) => {
    console.log("hello", data)
    const headerData = {
        visitor_name: "Visitor Name",
        mobile_number: "Mobile Number",
        company_name: "Company Name",
        designation: "Designation",
        visiting_as: "Visiting As",
        gst_number: "GST Number",
        // firm_id: "Firm ID",
        // invited_exhibitor_id: "Invited Exhibitor ID",
        email: "Email",
        street_address: "Street Address",
        // street_address_two: "Street Address 2",
        city: "City",
        state: "State",
        postal_code: "Postal Code",
        no_of_stores: "No. Of Stores",
        sqft_area: "Sqft Area",
        no_of_staff: "No Of Staff",
        company_category: "Company Category",
        // country: "Country",
        // hear_about_jff: "Hear About JFF",
        photo: "Photo",
        // categories:"Categories",
        // other_category:"Other Category",
        // category: "Category",
        id_proof_front: "ID Proof Front",
        id_proof_back: "ID Proof Back",
        barcode: "Barcode",
        // status: "Status",
        // logo: "Logo",
        approved_status: "Approved Status",
        // visiting_day: "Visiting Day",
        // hotel_detail: "Hotel Detail",
        // cab_detail: "Cab Detail",
        // flight_details: "Flight Details",
        // visitor_category_id: "Visitor Category ID",
        // active_inactive: "Active/Inactive",
        // visiting_card: "Visiting Card",
        // send_ticket: "Send Ticket",
        remarks_value: "Remarks",
        // travel_detail: "Travel Detail",
        // payment_detail: "Payment Detail",
        // webhook_payment_detail: "Webhook Payment Detail",
        // payment_status: "Payment Status",
        // payment_capture_detail: "Payment Capture Detail",
        // webhook_payment_capture_detail: "Webhook Payment Capture Detail",
        // gender: "Gender",
        // new_sort_number: "Sort Number",
        // user_type: "User Type",
        // category_visiting: "Category Visiting",
        // roomNo: "Room Number",
        // roomOccupancy: "Room Occupancy",
        // ticketsBookedBy: "Tickets Booked By",
        travelMode: "Travel Mode",
        // departurePNR: "Departure PNR",
        departureCity: "Departure City",
        flightTrainName: "Flight/Train Name",
        flightTrainNumber: "Flight/Train Number",
        arrivalTimeGoa: "Arrival Time",
        // goaAirport: "Goa Airport",
        // returnPNR: "Return PNR",
        returnCity: "Return City",
        returnFlightName: "Return Flight Name",
        returnFlightNumber: "Return Flight Number",
        returnFlightDepartureTime: "Return Flight Departure Time",
        // returnAirport: "Return Airport",
        hotelCheckInDate: "Hotel Check-In Date",
        hotelCheckOutDate: "Hotel Check-Out Date",
        departureDate: "Departure Date",
        returnFlightDate: "Return Flight Date",
        representative: "Representative",
        // alloted_car: "Alloted Car",
        // ticketsDepartureBookedBy: "Tickets Departure Booked By",
        // ticketsReturnBookedBy: "Tickets Return Booked By",
        travelReturnMode: "Travel Return Mode",
        // otp: "OTP"
    };

    return (
        <div>
            <Modal
                show={showAllDetails}
                onHide={() => setShowAllDetails(false)}
                animation={false}
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detail of the <strong>{data?.visitor_name}</strong></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Scrollbars style={{ height: '60vh' }}>
                        {data ? (
                            <ul className="list-group">
                                {Object.keys(data)
                                    .filter((key) => headerData.hasOwnProperty(key))
                                    .map((key) => (
                                        <li key={key} className="list-group-item d-flex justify-content-between align-items-center my-2 shadow">
                                            <span className="font-weight-bold">{headerData[key]}:</span>
                                            <span>{data[key]}</span>
                                        </li>
                                    ))}
                            </ul>
                        ) : (
                            <>No data...</>
                        )}

                        {/* Conditionally rendering the table */}
                        {data?.categories && data?.categories.length > 0 && (
                            
                            <Table className="table table-striped my-3 p-3 data-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.categories.map((category, index) => (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{category.name}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    disabled
                                                    className="form-control"
                                                    min="0"
                                                    value={category.percentage}
                                                   
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {data?.other_category && data?.other_category.length > 0 && (
                            <Table className="table table-striped my-3 p-3 data-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No.</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Priority</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.other_category.map((category, index) => (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{category.key}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    disabled
                                                    className="form-control"
                                                    min="0"
                                                    value={category.value}
                                                   
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </Scrollbars>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AllDetailOfPerson
