import "./App.css";
import "./index.css";
import { React } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/superAdmin/dashboard/Header";
import Footer from "./components/superAdmin/footer/Footer";
import Default from "./routes/Default";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import ExhibitorDashboardNavbar from "./components/exhibitor/exhibitor_dashboard/ExhibitorDashboardNavbar";
import VisitorForm from "./components/HtmlMainPage/MainPage";
import InvitedVIPGuestList from "./components/exhibitor/SubAdminArea/InvitedVIPGuestList";
import AdminInvitedVIPGuestList from "./components/AdminVIP/AdminInvitedVIPGuestList";
import MainRoute from "./routes/MainRoute";
import SuperAdminAreaLogin from "./components/superAdmin/Settings/SuperAdminAreaLogin";
import AdminVIPLogin from "./components/AdminVIP/AdminVIPLogin";
import ExhibitorLogin from "./components/exhibitor/Form/ExhibitorLogin";
import ExhibitorStaff from "./components/visitorform/ExhibitorStaff";
import ExhibitorVip from "./components/visitorform/ExhibitorVip";
import ExhibitorOwner from "./components/visitorform/ExhibitorOwner";
import VendorOwner from "./components/visitorform/VendorOwner";
import VendorStaff from "./components/visitorform/VendorStaff";
import Wrong404 from "./404/Wrong404";
import FrontEndPage from "./components/HtmlMainPage/FrontPage";
import HospitalityLogin from "./components/Hospitality/HospitalityLogin";
import CabServiceLogin from "./components/CabService/CarServiceLogin";
import FlightServicelogin from "./components/FlightService/FlightServiceLogin";
import BuyerRegistration from "./components/HtmlMainPage/BuyerRegistration";
import KceVisitorRegistration from "./components/HtmlMainPage/KceVisitorRegistration";
import SuccessFullPage from "./components/HtmlMainPage/SuccessFullPage";
import FailPage from "./components/HtmlMainPage/FailPage";
import PaymentComponent from "./components/HtmlMainPage/PaymentComponent";
import BuyerAdminLogin from "./components/BuyerAdmin/BuyerAdminLogin";
import VisitorAdminLogin from "./components/visitorAdmin/VisitorAdminLogin";
import Exhibitor from "./components/superAdmin/exhibitor/Exhibitor"
import BuyerRegistrationForm from './components/HtmlMainPage/BuyerLogin.js'
import IgiStaffRegistrationForm from './components/HtmlMainPage/IgiStaffRegistrationForm.js'
// ThankYou
import ThankYou from './components/HtmlMainPage/ThankYou.js'

import NewDataData from './components/HtmlMainPage/NewDataData.js'
function App() {
  return (
    <>
      {/* <MainPage/> */}
      {/* <Suspense fallback={<Spinner animation="border" size="lg" />}> */}
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center content-height">
            Loading... &nbsp; &nbsp;
            <Spinner animation="border" size="lg" />
          </div>
        }
      >
        <Routes>
          <Route path="/adminvip/login" element={<AdminVIPLogin />} />
          <Route path="/superadmin/login" element={<SuperAdminAreaLogin />} />
          <Route path="/exhibitor/login" element={<ExhibitorLogin />} />
          <Route path="/hospitality/login" element={<HospitalityLogin />} />
          <Route path="/cab-service/login" element={<CabServiceLogin />} />
          <Route path="/buyer-admin/login" element={<BuyerAdminLogin />} />
          <Route path="/visitor-admin/login" element={<VisitorAdminLogin />} />
          <Route path="/agent/login" element={<ExhibitorLogin />} />
          <Route path="/associate/login" element={<ExhibitorLogin />} />
          <Route path="/sponser/login" element={<ExhibitorLogin />} />

          <Route
            path="/flight-service/login"
            element={<FlightServicelogin />}
          />
          <Route path="/404" element={<Wrong404 />} />
          <Route path="/" element={<FrontEndPage />} />
          {/* <Route
            path="/exhibitor-staff-registration"
            element={<ExhibitorStaff />}
          /> */}
          <Route path="/registration" element={<VisitorForm />} />
          <Route path="/payment-successful" element={<SuccessFullPage />} />
          <Route path="/buyer-registration-form" element={<BuyerRegistrationForm />} />
          {/* <Route path="/buyer-registration-form" element={<BuyerRegistrationForm />} /> */}
          <Route path="/igi-staff-registration-form" element={<IgiStaffRegistrationForm />} />


          {/* <Route path="/payment" element={<PaymentComponent />} /> */}

          <Route path="/payment-failed" element={<FailPage />} />
          <Route path="/new-data-data" element={<NewDataData />} />
          <Route path="/thank-you" element={<ThankYou />} />



          <Route
            path="/visitor-registration"
            element={<KceVisitorRegistration />}
          />
{/* 
          <Route
            path="/exhibitor/create"
            element={< Exhibitor/>}
          /> */}
          {/* <Route
            path="/exhibitor-owner-registration"
            element={<ExhibitorOwner />}
          /> */}
          <Route
            path="/vendor-owner-registration/:barcode"
            element={<VendorOwner />}
          />
          <Route
            path="/vendor-staff-registration/:barcode"
            element={<VendorStaff />}
          />
          <Route path="/vip-guest-registration" element={<ExhibitorVip />} />
          <Route path="/buyer-registration" element={<BuyerRegistration />} />
          <Route path="/404" element={<Wrong404 />} />
          <Route path="*" element={<Default />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
