import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { message, Button, Form, Input, notification } from 'antd';
import Footer from '../superAdmin/footer/Footer'
import BuyerRegistrationForm from './BuyerRegistrationForm'
import logo from '../../assets/image/logo-image.png'
import axios from 'axios';
const BuyerForm = () => {
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [form] = Form.useForm();
    const [barcode, setBarcode] = useState(null)
    const [showForm, setShowForm] = useState(false)
    const openNotification = (message, description = "") => {
        notification.success({
            message,
            description,
        });
    };
    const handlePhoneSubmit = (values) => {
        console.log("hello", "i am here", values)
        // Simulate sending OTP
        axios
            .post(`${process.env.REACT_APP_API_END_POINT}/add-number-in-list`, {
                mobile_number: values?.phoneNumber,
            })
            .then((resData) => {
                console.log(resData.data);
                if (resData.data.otp_send) {
                    setBarcode(resData.data.barcode)
                    setIsOtpSent(true);
                    openNotification('OTP has been sent to your phone number!');
                } else {
                    notification.error({
                        message: "Allready Present",
                        description: "",
                    });
                }
            })
            .catch((error) => {
                console.log("error:", error.message);
            });
    };

    const handleOtpSubmit = (values) => {
        axios
            .post(`${process.env.REACT_APP_API_END_POINT}/otp-varification`, {
                otp: values?.otp,
                barcode,
            })
            .then((resData) => {
                if (resData?.data?.otp_check) {
                    openNotification(resData.data.message);
                    localStorage.setItem('from_status', resData?.data?.otp_check)
                    localStorage.setItem('barcode', resData?.data?.barcode)
                    localStorage.setItem('mobile_number',resData?.data?.mobile_number)
                    setShowForm(true)

                } else {

                }

                // if (resData.data.message === "OTP verified") {
                //     setCheckReload(!checkReload);
                //     setDataShow(4);
                //     localStorage.setItem("barcode", barcode_shared);

                // } else if (resData.data.message === "all data aviliable") {
                //     console.log("varify otp", resData.data.data[0]);
                //     setCurrentBuyer(resData.data.data[0]);
                //     setDataShow(3);
                // }
            })
            .catch((error) => {
                // openNotification(resData.data.message);

                console.log("error:", error.message);
            });
        // Here you can add further actions after OTP verification
    };

    const handleChangeNumber = () => {
        setIsOtpSent(false);
        setPhoneNumber('');
        setOtp('');
        form.resetFields();
    };



    return (
        <>

            {
                localStorage.getItem('from_status') || showForm ?
                    (<>
                        <BuyerRegistrationForm phoneNumber={phoneNumber} />

                    </>)

                    : (
                        <>
                            <div className='d-flex justify-content-center align-items-center my-5'>
                                <img src={logo} style={{ width: '200px', heigh: 'auto' }} />
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-5" style={{ height: 'auto' }} >
                                <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '5px' }} >
                                    <h2 className="text-center">Buyer of {process.env.REACT_APP_FULL_SHOW_NAME}</h2>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={isOtpSent ? handleOtpSubmit : handlePhoneSubmit}
                                        className='col-md-12 col-sm-12 d-flex align-items-center justify-content-center'
                                        style={
                                            {
                                                columnGap: '20px'
                                            }
                                        }

                                    >
                                        {!isOtpSent && (
                                            <Form.Item
                                                label="Phone Number"
                                                name="phoneNumber"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter your phone number!'
                                                    },
                                                    {
                                                        pattern: /^[0-9]{10}$/,
                                                        message: 'Phone number must be 10 digits!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    type="tel"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    maxLength={10}
                                                />
                                            </Form.Item>
                                        )}

                                        {isOtpSent && (
                                            <>
                                                <Form.Item
                                                    label="OTP"
                                                    name="otp"
                                                    rules={[{ required: true, message: 'Please enter the OTP!' }]}
                                                >
                                                    <Input
                                                        value={otp}
                                                        onChange={(e) => setOtp(e.target.value)}
                                                        maxLength={4}
                                                    />
                                                </Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Verify OTP
                                                </Button>
                                                <Button type="default" onClick={handleChangeNumber} >
                                                    Change Number
                                                </Button>
                                            </>
                                        )}

                                        {!isOtpSent && (
                                            <Button type="primary" htmlType="submit">
                                                Send OTP
                                            </Button>
                                        )}
                                    </Form>
                                </div>
                            </div>
                        </>
                    )
            }
            <Footer />

        </>
    );
};

export default BuyerForm;
